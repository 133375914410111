
import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import { currencyFilter } from '@/utils/string';

interface CalculatedPrice {
  priceHigh: string;
  partnerTakeRate: string;
  userFacingPriceLog: string;
}

@Component
export default class OfferAmountField extends Vue {
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: Number, default: null }) value!: number | null;
  @Prop({ type: Number, default: null }) quoteId!: number | null;
  @Prop({ type: Number, default: null }) companyId!: number | null;
  @Prop({ type: Number, default: null }) tripId!: number | null;
  @Prop({ type: Number, default: null }) offerAmount!: number | null;

  canCalculate = false;
  calculatedPrice: CalculatedPrice | null = null;
  hasCalculatedPrice = false;
  loading = false;
  errorMessages: string[] = [];

  get offerAmountLabel(): string {
    return this.offerAmount
      ? `Offer Amount: ${currencyFilter(this.offerAmount)}`
      : '';
  }

  async fillCalculatedOffer(): Promise<void> {
    if (!this.hasCalculatedPrice) {
      try {
        this.loading = true;
        await this.calculatePrice();
      } catch (e) {
        this.errorMessages = ['Error fetching rates'];
        return;
      } finally {
        this.loading = false;
      }
    }

    if (this.calculatedPrice != null) {
      const priceHigh = parseInt(this.calculatedPrice.priceHigh);
      const takeHomeAmount = (
        priceHigh *
        (1 - parseInt(this.calculatedPrice.partnerTakeRate) / 100)
      ).toFixed(2);
      (this.$refs.input as any).internalValue = takeHomeAmount;
      this.$emit('input', takeHomeAmount);
    } else {
      this.errorMessages = ['No rates found'];
    }
  }

  async calculatePrice(): Promise<void> {
    const offer = await this.$store.dispatch('quotes/getQuotePricingById', {
      quoteId: this.quoteId,
      companyId: this.companyId,
    });
    this.calculatedPrice = offer.data.pricings.find(
      (p: any) =>
        p.tripId === this.tripId &&
        p.companyId === this.companyId &&
        p.autoSelected
    );

    this.hasCalculatedPrice = true;
  }
}
