<template>
  <v-container>
    <template v-if="row.action === 'VERIFY_CREDIT_CARD'">
      <v-layout row>
        <v-flex sm3>
          <div class="res-table-detail-padded">
            <div class="headline">Verify Credit Card</div>
            <span>
              Are you sure the last 4 digits of the card as well as the name of
              the cardholder match?
            </span>
            <br />
            <br />
          </div>
        </v-flex>
        <v-flex sm5>
          <div class="res-table-detail-padded">
            <table class="elevation-2 full-width">
              <tr>
                <th>Cardholder Name</th>
                <th>Debit/Credit Card</th>
              </tr>
              <tr>
                <td>{{ op(firstTransaction, 'meta/name') }}</td>
                <td>
                  <span>{{ op(firstTransaction, 'meta/type_label') }}</span>
                  Ending in
                  <span>{{ op(firstTransaction, 'meta/mask') }}</span>
                </td>
              </tr>
            </table>
          </div>
        </v-flex>
        <v-flex sm4>
          <div class="res-table-detail-padded">
            <v-btn
              :id="`${id}-table-detail-button-cancel-credit-card`"
              class="btn-secondaryaction"
              @click="() => (props.expanded = false)"
            >
              Cancel
            </v-btn>
            <v-btn
              :id="`${id}-table-detail-button-verify-credit-card`"
              class="btn-primaryaction"
              @click="verifyCC"
            >
              Verify
            </v-btn>
          </div>
        </v-flex>
      </v-layout>

      <v-progress-linear v-if="loading" :indeterminate="true" height="2" />
    </template>
    <template v-if="row.action === 'REFER'">
      <div
        v-if="
          reservationV2.referredTo &&
          reservationV2.referredTo.length === 0 &&
          !reservationV2.confirmed
        "
      >
        <div>
          <div class="res-table-detail-padded">
            <div class="headline">Confirm Reservation?</div>
            <span>This reservation has no referrals.</span>
            <br />
            <br />
          </div>
        </div>
        <div class="column is-5" />
        <div class="column">
          <div class="res-table-detail-padded confirm-buttons">
            <v-btn
              :id="`${id}-table-detail-button-cancel-reservation`"
              class="btn-secondaryaction"
              @click="() => (props.expanded = false)"
            >
              Cancel
            </v-btn>
            <v-btn
              :id="`${id}-table-detail-button-confirm-reservation`"
              class="btn-primaryaction"
              @click="confirmReservation"
            >
              Confirm
            </v-btn>
          </div>
        </div>
      </div>
      <div
        v-else-if="
          unacceptedReferrals.length === 0 &&
          reservationV2.referredTo &&
          reservationV2.referredTo.length &&
          !reservationV2.confirmed
        "
      >
        <div>
          <div class="res-table-detail-padded">
            <div class="headline">Confirm Reservation</div>
            <span>
              Have you spoken with the affiliate and confirmed the details of
              this referral?
            </span>
            <br />
            <br />
          </div>
        </div>
        <div class="column is-5" />
        <div class="column">
          <div class="res-table-detail-padded confirm-buttons">
            <v-btn
              :id="`${id}-table-detail-button-cancel-reservation`"
              class="btn-secondaryaction"
              @click="() => (props.expanded = false)"
            >
              Cancel
            </v-btn>
            <v-btn
              :id="`${id}-table-detail-button-confirm-reservation`"
              class="btn-primaryaction"
              :disabled="missingGarages"
              @click="confirmReservation"
            >
              Confirm
            </v-btn>
            <div v-if="missingGarages" class="error-garage">
              One of the vehicles on this reservation is missing a garage.
              Please update and try again.
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div>
          <div class="res-table-detail-padded">
            <div class="headline">Confirm Reservation</div>
            <span v-if="reservationV2.confirmed">
              This reservation is already confirmed.
            </span>
            <span v-else>
              All referrals must be accepted before confirmation.
            </span>
            <br />
            <br />
          </div>
        </div>
        <div class="column is-5" />
        <div class="column" />
      </div>
      <v-progress-linear v-if="loading" :indeterminate="true" height="2" />
    </template>
    <template v-if="row.action === 'DELETE'">
      <div>
        <div>
          <div>
            <CRIcon
              color="error"
              width="20"
              height="20"
              style="margin-bottom: -4px; margin-left: -16px"
              material
            >
              error_color
            </CRIcon>
            <span
              :style="`color: ${$cr.theme.error}; padding: 0px 5px; font-size: 16px;`"
            >
              Warning, this action cannot be undone
            </span>
            <CRIcon
              color="error"
              width="20"
              height="20"
              style="margin-bottom: -4px"
              material
            >
              error_color
            </CRIcon>
            <br />
            <br />
            <div class="headline">Delete Reservation</div>
            <span>Are you sure you would like to delete this reservation?</span>
            <br />
            <br />
          </div>
        </div>
        <div class="column is-5" />
        <div class="column">
          <div class="unpad-buttons">
            <v-btn class="btn-secondaryaction" @click="cancel">
              No, go back
            </v-btn>
            <v-btn
              id="reservation-details-button-delete"
              color="error"
              @click="deleteReservation"
            >
              Yes, delete reservation
            </v-btn>
          </div>
        </div>
      </div>
    </template>
    <v-layout v-if="row.action === 'TOGGLE_HOLD'" row wrap>
      <v-flex xs6>
        <div v-if="row.reservationStatus === 'hold'">
          <div class="headline">Remove Hold</div>
          <div>
            This action will place the reservation in
            <strong>Upcoming</strong>
            status.
          </div>
        </div>
        <div v-else>
          <div class="headline">Hold Reservation</div>
          <div>
            This action will place the reservation in
            <strong>Hold</strong>
            status.
          </div>
        </div>
      </v-flex>
      <v-flex xs6>
        <v-textarea
          :id="`${id}-table-detail-text-area-add-comment`"
          ref="commentBody"
          v-model="holdNote"
          :value="
            row.reservationStatus === 'hold'
              ? 'REMOVING HOLD'
              : 'PLACING ON HOLD'
          "
          flat
          placeholder="Add a Comment"
          solo
        />
      </v-flex>

      <v-flex class="toggle-hold-btn-container" text-xs-right xs12>
        <v-btn
          :id="`${id}-table-detail-button-cancel-hold-reservation`"
          class="remove-right-margin btn-secondaryaction"
          @click="() => (props.expanded = false)"
        >
          Cancel
        </v-btn>
        <v-btn
          :id="`${id}-table-detail-confirm-hold-reservation`"
          class="remove-right-margin btn-primaryaction"
          @click="toggleHoldReservation"
        >
          Confirm
        </v-btn>
      </v-flex>
      <v-flex xs12>
        <v-progress-linear v-if="loading" :indeterminate="true" height="2" />
      </v-flex>
    </v-layout>
    <template v-if="row.action === 'REFERRED_TO_DETAIL'">
      <ReservationReferralDetail
        :id="`${id}-table-detail`"
        :row="row"
        :reservation="reservationV2"
        :customer-account="customerAccount"
        @refresh-query-request="refreshQuery"
      />
      <v-progress-linear v-if="loading" :indeterminate="true" height="2" />
    </template>
    <template v-if="row.action === 'REFERRAL_ACTIONS_DETAIL'">
      <div class="contact-detail">
        <v-card-title primary-title>
          <v-layout row wrap>
            <v-flex xs6>
              <div class="headline">
                {{ op(reservation, 'company/name') }}
              </div>
              <span>
                Phone:
                <a
                  :id="`${id}-table-detail-href-company-phone`"
                  :href="`tel:${op(reservation, 'company/phone')}`"
                >
                  {{ phoneFormatFilter(op(reservation, 'company/phone')) }}
                </a>
                <br />
              </span>
              <span>
                Email:
                <a
                  :id="`${id}-table-detail-href-mail-to-company`"
                  :href="`mailto:${op(reservation, 'company/email')}`"
                >
                  {{ op(reservation, 'company/email') }}
                </a>
                <br />
              </span>
              <p>
                Address:
                {{ op(reservation, 'company/address/name') }}
                <br />
              </p>
            </v-flex>
            <v-flex xs6>
              <v-layout row wrap>
                <v-flex xs12>
                  <v-layout row wrap>
                    <v-flex
                      bottom-left
                      bottom-right
                      has-border
                      text-xs-center
                      top-left
                      top-right
                      xs12
                    >
                      <div class="subheading">
                        Referral # {{ row.managedId }}
                      </div>
                      <ReferralRejectDialog
                        v-if="
                          !['accepted', 'confirmed'].includes(
                            row.referralStatus
                          )
                        "
                        :id="`${id}-table-detail-button-reject-referral-as-provider`"
                        :loading="loading"
                        :active="childReservationV2.active"
                        text="Mark Declined"
                        @reject="
                          (reasonType, notes) =>
                            rejectReferralAsProvider(
                              row.reservationId,
                              reasonType,
                              notes
                            )
                        "
                      />
                      <MarkReferralAccepted
                        v-if="
                          !['accepted', 'confirmed'].includes(
                            row.referralStatus
                          )
                        "
                        :id="`${id}-table-detail-button-accept-referral-as-provider`"
                        :reservation-id="row.managedId"
                        :customer-account="customerAccount"
                        :loading="loading"
                        @reject="
                          () => rejectReferralAsProvider(row.reservationId)
                        "
                        @accept="
                          (referralAcceptanceESignature) =>
                            acceptReferralAsProvider(
                              row.reservationId,
                              referralAcceptanceESignature
                            )
                        "
                      />
                      <v-btn
                        v-if="
                          ['accepted', 'confirmed'].includes(row.referralStatus)
                        "
                        :id="`${id}-table-detail-button-confirm-accepted-confirmed`"
                        color="success"
                        outline
                      >
                        <v-icon>check_circle_outline</v-icon>
                        <strong>Accepted</strong>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card-title>
      </div>
      <v-progress-linear v-if="loading" :indeterminate="true" height="2" />
    </template>
    <CustomerDetail
      v-if="row.action === 'CUSTOMER_DETAIL'"
      :row="customer"
      :customer-account="customerAccount"
      :bookings="bookings"
      :industries="industries"
      :organization="organization"
    />
    <DriverDetail v-if="row.action === 'DRIVER-DETAIL'" :row="row" />
    <VehicleDetail v-if="row.action === 'VEHICLE-DETAIL'" :row="row" />
    <template v-if="row.action === 'BOOKED_BY_DETAIL'">
      <div class="contact-detail">
        <v-card-title primary-title>
          <div>
            <div class="headline">
              {{ op(reservation, 'bookedBy/firstName') }}
              {{ op(reservation, 'bookedBy/lastName') }}
            </div>

            <span>
              Email:
              <a
                :id="`${id}-table-detail-href-email-booked-by`"
                :href="`mailto:${op(reservation, 'bookedBy/email')}`"
              >
                {{ op(reservation, 'bookedBy/email') }}
              </a>
              <br />
            </span>
          </div>
        </v-card-title>
      </div>

      <v-progress-linear v-if="loading" :indeterminate="true" height="2" />
    </template>
    <template v-if="row.action === 'ASSIGNED_DRIVER_VEHICLE_DETAIL_BROKER'">
      <div v-if="!loading" class="contact-detail">
        <ReservationAssignDetailBroker
          :id="`${id}-table-detail`"
          :row="row"
          :reservation="reservationV2"
          @refresh-query-request="refreshQuery"
        />
      </div>
      <v-progress-linear v-if="loading" :indeterminate="true" height="2" />
    </template>
    <span v-if="action === 'QUICK_REFER'">
      <ReservationQuickRefer
        v-if="reservationV2.reservationId"
        :key="`reservation-quick-refer-expansion-${reservationV2.reservationId}`"
        :reservation="reservationV2"
        @refresh-query-request="refreshQuery"
      />
      <v-progress-linear v-if="loading" :indeterminate="true" height="2" />
    </span>
    <ReservationComments
      v-if="action === 'COMMENTS'"
      :id="`${id}-table-detail`"
      :key="reservation.reservationId"
      :reservation-id="reservation.reservationId"
      :reservation-comments="reservation.reservationComments"
      @refresh-query-request="refreshQuery"
    />
    <PaymentSidebarWrapper
      :id="`${id}-table-detail`"
      v-model="sidebarAction"
      :row="row"
      @refresh="refreshQuery"
    />
    <span v-if="action === 'EDIT_TRIP_NAME'">
      <EditTripName
        :id="`${id}-table-detail`"
        :key="reservation.reservationId"
        :reservation="row"
        :trip-id="reservation.tripId"
        @refresh-query-request="refreshQuery"
      />
    </span>
    <span v-if="action === 'detail'">
      <ReservationQuickView
        v-if="childReservationV2 && childReservationV2.parentReservationId"
        :id="`${id}-table-detail`"
        :key="reservation.reservationId"
        :reservation="childReservationV2"
        :child-reservation="childReservationV2"
      />
      <ReservationQuickView
        v-else
        :id="`${id}-table-detail`"
        :key="reservation.reservationId"
        :reservation="reservationV2"
        :child-reservation="childReservationV2"
      />
    </span>
    <span v-if="action === 'BID'">
      <BrokerReservationBidForm
        :id="`${id}-table-detail`"
        :row="row"
        :parent="props"
        @refresh-query-request="refreshQuery"
      />
    </span>
  </v-container>
</template>

<script>
import * as logger from '@/utils/logger'
import { phoneFormatFilter } from '@/utils/phone'
import op from 'simple-object-path'
import { mapActions } from 'vuex'
import ReservationQuickView from './ReservationQuickView.vue'
import ReservationQuickRefer from './ReservationQuickRefer.vue'
import ReservationComments from './ReservationCommentsQuickView.vue'
import EditTripName from '@/components/EditTripName.vue'
import ReservationReferralDetail from './ReservationReferralDetail.vue'
import ReservationAssignDetailBroker from './ReservationAssignDetailBroker.vue'
import BrokerReservationBidForm from './BrokerReservationBidForm.vue'
import CustomerDetail from '@/components/CustomerDetail.vue'
import DriverDetail from '@/components/DriverDetail.vue'
import VehicleDetail from '@/components/VehicleDetail.vue'
import { authComputed } from '@/state/helpers'
import MarkReferralAccepted from '@/components/MarkReferralAccepted.vue'
import ReferralRejectDialog from '../components/ReferralRejectDialog.vue'
import PaymentSidebarWrapper from '@/components/PaymentSidebarWrapper.vue'
import admin from '@/services/admin'
import customers from '@/services/customers'
import customerAccounts from '@/services/customerAccounts'
import { EventBus } from '@/utils/event-bus'

export default {
  components: {
    ReservationQuickView: ReservationQuickView,
    ReservationQuickRefer: ReservationQuickRefer,
    ReservationComments,
    ReservationReferralDetail,
    ReservationAssignDetailBroker,
    BrokerReservationBidForm,
    CustomerDetail,
    DriverDetail,
    VehicleDetail,
    MarkReferralAccepted,
    EditTripName,
    ReferralRejectDialog,
    PaymentSidebarWrapper,
  },
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    props: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      reservation: {},
      reservationV2: {},
      childReservation: {},
      childReservationV2: {},
      firstTransaction: undefined,
      loading: false,
      drivers: [],
      journeys: [],
      referrals: [],
      holdNote: undefined,
      requiredVehicles: 0,
      requiredDrivers: 0,
      unacceptedReferrals: [],
      op,
      phoneFormatFilter,
      customerAccount: null,
      missingGarages: false,
      sidebarAction: '',
      customer: {},
      organization: null,
      industries: null,
      bookings: null,
      action: null,
    }
  },
  computed: {
    ...authComputed,
  },
  watch: {
    'row.action': {
      immediate: true,
      handler: function (action) {
        if (
          action === 'COLLECT_PAYMENT' ||
          action === 'COLLECT_CONTRACT_PAYMENT'
        ) {
          this.sidebarAction = 'collectPayment'
        }
      },
    },
  },
  async mounted() {
    this.action = this.props.item.action
    this.loading = true
    this.$emit('row-expanded', this.props)
    this.holdNote =
      this.row.reservationStatus === 'hold'
        ? 'REMOVING HOLD'
        : 'PLACING ON HOLD'
    await this.getReservation()
    this.loading = false

    EventBus.$on('refresh-reservation-detail', () => this.cancel())
  },
  methods: {
    ...mapActions({ showAlert: 'app/showAlert' }),
    async getReservation() {
      let { reservationId, managedId, parentReservationId } = this.row

      let isReferral = parentReservationId != null

      const reservationV2Data = await this.$store.dispatch(
        'reservations/reservationv2ById',
        {
          reservationId: isReferral ? parentReservationId : managedId
        }
      )

      let childReservationV2Data = null
      if (isReferral) {
        childReservationV2Data = await this.$store.dispatch(
          'reservations/reservationv2ById',
          { reservationId }
        )
      } else {
        childReservationV2Data = reservationV2Data
      }

      const reservationV2 = reservationV2Data?.data
      const childReservationV2 = childReservationV2Data?.data
      let customerData = null
      if (reservationV2.customerId) {
        customerData = await customers.getCustomer(reservationV2.customerId)
        this.customer = { ...this.row, customer: customerData?.data?.customer }
      } else {
        this.customer = {
          ...this.row,
          customer: {
            firstName: reservationV2.customerName,
            phone: reservationV2.customerPhoneNumber,
            email: reservationV2.customerEmail,
          },
        }
      }

      this.reservation = reservationV2
      this.reservationV2 = reservationV2
      this.childReservation = childReservationV2
      this.childReservationV2 = childReservationV2
      Object.assign(this.drivers, reservationV2.drivers)
      Object.assign(this.journeys, reservationV2.journeys)
      this.firstTransaction = this.reservation?.transactions?.[0]
      const referrals = this.reservationV2.referredTo
      this.reservationV2.maxReferralAmount = this.row.maxReferralAmount

      if (this.reservation.journeys) {
        this.reservation.journeys.forEach((journey) => {
          if (!journey.vehicle.garage) {
            this.missingGarages = true
          }
        })
      }

      if (
        customerData?.data?.customer?.customerId !==
        childReservationV2.customerId
      ) {
        const { data } = await customers.getCustomer(
          this.childReservationV2.customerId
        )
        customerData = data
      } else {
        customerData = customerData?.data
      }
      if (customerData?.customer?.customerAccountId) {
        const {
          data: customerAccountData,
        } = await customerAccounts.getCustomerAccount(
          customerData?.customer?.customerAccountId
        )
        this.customerAccount = customerAccountData
      }
      this.organization = customerData?.customer?.organization
      this.industries = (customerData?.customer?.industries || '')
        .split('||')
        .join(', ')
      this.bookings = customerData?.customer?.bookings

      if (referrals) {
        this.unacceptedReferrals = this.reservationV2.referredTo.filter(
          (referral) => referral?.referralStatus === 'pending'
        )
      } else {
        this.unacceptedReferrals = []
      }
    },
    async deleteReservation() {
      const response = await admin
        .deactivateReservation(this.row.managedId)
        .catch((error) => {
          logger.warn(error)
          return false
        })
      if (response) {
        this.props.expanded = false
        this.row.deleteMe(this.row)
      }
    },
    cancel() {
      this.props.expanded = false
    },
    async verifyCC() {
      this.loading = true
      await this.$store
        .dispatch('reservations/verifyCC', this.reservation.reservationId)
        .catch(logger.error)
      this.loading = false
      this.reservation.ccVerified = true
      this.row.ccVerified = true
      this.props.expanded = false
    },
    async confirmReservation() {
      this.loading = true
      this.reservation.confirmed = true
      await this.$store
        .dispatch('reservations/putReservation', this.reservation)
        .catch(logger.error)
      this.loading = false
      this.row.confirmed = true
      this.props.expanded = false
      this.showAlert({
        type: 'success',
        message: `Reservation confirmed!`,
      })
    },
    async toggleHoldReservation() {
      this.loading = true

      let newReservationStatus =
        this.row.reservationStatus === 'hold' ? 'upcoming' : 'hold'

      const response = await this.$store
        .dispatch('reservations/updateReservationStatus', {
          reservationId: this.reservation.reservationId,
          reservationStatus: newReservationStatus,
          userId: this.currentUser.userId,
        })
        .catch(logger.error)
      const { status } = response
      if (status === 200) {
        const { value: note = '' } = this.$refs.commentBody
        const params = {
          reservation: {
            id: this.reservation.reservationId,
          },
          note,
        }
        if (note && note.length) {
          await this.$store.dispatch(
            'reservations/addReservationComment',
            params
          )
        }
        this.row.reservationStatus = newReservationStatus
      }
      this.loading = false
      this.props.expanded = false
    },
    async acceptReferralAsProvider(
      reservationId,
      referralAcceptanceESignature = ''
    ) {
      this.loading = true
      const params = {
        reservationId,
        referralAcceptanceESignature,
      }
      try {
        await this.$store.dispatch('reservations/acceptReferral', params)
        this.refreshQuery()
      } catch (err) {
        this.showAlert({
          type: 'error',
          message: 'Error accepting referral, please try again',
        })
        logger.error(err)
        this.loading = false
      }
      this.loading = false
    },
    async rejectReferralAsProvider(referralId, reasonType, notes) {
      this.loading = true
      try {
        const params = {
          reservationId: referralId,
          referralId,
          body: {
            referralRejectionReasonTypeId: reasonType,
            notes: notes,
          },
        }
        // reject the current referral
        await this.$store
          .dispatch('reservations/rejectReferral', params)
          .catch(logger.error)
        this.refreshQuery()
      } catch (err) {
        this.showAlert({
          type: 'error',
          message: 'Error rejecting referral, please try again',
        })
        logger.error(err)
        this.loading = false
      }
      this.loading = false
    },
    refreshQuery() {
      this.$emit('refresh-query-request')
    },
  },
}
</script>

<style lang="scss">
.v-btn.remove-right-margin {
  margin-right: 0 !important;
}

.detail-container {
  padding: 4px;
  background-color: $blue-pale;
}

.detail {
  padding: 10px;
}

.half-width {
  width: 50%;
}

.center-contents {
  text-align: center;
}

.contact-detail {
  margin-top: 0;
}

.error-garage {
  padding-left: 10px;
  color: $red;
  padding-top: 3px;
}

.res-table-detail-padded {
  padding: 20px;
}

.float-right {
  float: right;
}

.confirm-buttons {
  margin-left: -8px;
}

.has-border {
  border-top: 1px solid gray;
  border-left: 1px solid gray;

  &.top-right {
    border-right: 1px solid gray;
    border-top-right-radius: 4px;
  }

  &.top-left {
    border-top-left-radius: 4px;
  }

  &.bottom-left {
    border-bottom: 1px solid gray;
    border-bottom-left-radius: 4px;
  }

  &.bottom-right {
    border-right: 1px solid gray;
    border-bottom: 1px solid gray;
    border-bottom-right-radius: 4px;
  }
}
</style>
