import Vue from 'vue'
import { authComputed } from '@/state/helpers'
import { getReservationItineraryAsString } from '@/utils/reservation'
import router from '@/router/index'
import { store } from '@/state/store'
import { EventBus } from '@/utils/event-bus'

interface Row {
  referralStatus?: string
  contractId?: string
  reservationId: number
  item?: { reservationId: number }
  paymentStatus?: string
  billAfterServices?: boolean
  needsManualReferral?: boolean
  isPendingConfirmation?: boolean
  isCharterUpQuote?: boolean
  poNumber?: string
  poStatus?: string
  purchaseOrderStatuses?: string[]
  externalId?: string
}

const { getters = {}, dispatch = {} } = store
const currentUser = getters['auth/currentUser']

export default async function (component: any) {
  function MenuDataMapper(
    row: Row,
    rowProps: Record<string, any>,
    handleAction: (action: string, props: Record<string, any>) => void,
    canOpenCharterUpQuote: boolean,
    isAdmin: boolean,
    isAccountsReceivable: boolean
  ) {
    if (row.contractId) {
      return [
        {
          icon: () => 'create',
          viewBox: '0 0 24 24',
          title: () => 'Edit Trip Name',
          click: () => handleAction('EDIT_TRIP_NAME', rowProps),
        },
        {
          icon: () => 'create',
          viewBox: '0 0 24 24',
          title: () => 'Edit Trip',
          click: () =>
            router.push({
              path: `/reservations/edit/${rowProps.item.reservationId}`,
              props: {
                mode: 'edit',
              },
            }),
        },
        {
          icon: () => 'copy',
          title: () => 'Copy Itinerary',
          click: async () => {
            const itinerary = await getReservationItineraryAsString(row)
            navigator.clipboard.writeText(itinerary)
            store.dispatch('app/showAlert', {
              type: 'success',
              message: 'Itinerary info copied to your clipboard',
            })
          },
          condition: () => currentUser && currentUser.companyId === 2,
        },
        {
          icon: () => 'info',
          title: () => 'Remove From MARGE',
          click: async () => {
            await store.dispatch('reservations/setManualReferralNeeded', {
              reservationIds: [row.reservationId],
              needsManualReferral: true,
            })
            EventBus.$emit('global-table-view-refresh')
            store.dispatch('app/showAlert', {
              type: 'success',
              message: 'Removed from MARGE',
            })
          },
          condition: () => !row.needsManualReferral,
        },
        {
          icon: () => 'info',
          title: () => 'Restart MARGE',
          click: async () => {
            await store.dispatch('reservations/setManualReferralNeeded', {
              reservationIds: [row.reservationId],
              needsManualReferral: false,
            })
            EventBus.$emit('global-table-view-refresh')
            store.dispatch('app/showAlert', {
              type: 'success',
              message: 'MARGE Restarted',
            })
          },
          condition: () => row.needsManualReferral,
        },
        {
          icon: () => 'secure_payment',
          title: () => 'Collect Payment',
          click: () => handleAction('COLLECT_CONTRACT_PAYMENT', rowProps),
          condition: () =>
            ['not_paid', 'partially_paid'].includes(row.paymentStatus),
        },
      ]
    }

    return [
      {
        icon: () => 'trash',
        title: () => 'Delete Reservation',
        condition: () => isAdmin,
        quickAccess: false,
        click: () => handleAction('DELETE', rowProps),
      },
      {
        icon: () => 'note',
        title: () => 'Comments',
        click: () => handleAction('COMMENTS', rowProps),
      },
      {
        icon: () => 'purchase_order',
        title: () => 'Edit Purchase Order',
        click: () => editPurchaseOrder(row),
        condition: () => isAccountsReceivable && row.billAfterServices,
      },
      {
        icon: () => 'copy',
        title: () => 'Copy Itinerary',
        click: async () => {
          const itinerary = await getReservationItineraryAsString(row)
          navigator.clipboard.writeText(itinerary)
          store.dispatch('app/showAlert', {
            type: 'success',
            message: 'Itinerary info copied to your clipboard',
          })
        },
        condition: () => currentUser && currentUser.companyId === 2,
      },
      {
        icon: () => 'info',
        title: () => 'Remove from MARGE',
        click: async () => {
          await store.dispatch('reservations/setManualReferralNeeded', {
            reservationIds: [row.reservationId],
            needsManualReferral: true,
          })
          EventBus.$emit('global-table-view-refresh')
          store.dispatch('app/showAlert', {
            type: 'success',
            message: 'Removed from MARGE',
          })
        },
        condition: () => !row.needsManualReferral,
      },
      {
        icon: () => 'info',
        title: () => 'Restart Marge',
        click: async () => {
          await store.dispatch('reservations/setManualReferralNeeded', {
            reservationIds: [row.reservationId],
            needsManualReferral: false,
          })
          EventBus.$emit('global-table-view-refresh')
          store.dispatch('app/showAlert', {
            type: 'success',
            message: 'MARGE Restarted',
          })
        },
        condition: () => row.needsManualReferral,
      },
      {
        icon: () => 'error_color',
        title: () =>
          row.isPendingConfirmation
            ? 'Remove Pending Confirmation'
            : 'Mark Pending Confirmation',
        click: async () => {
          await store
            .dispatch(
              'reservations/togglePendingConfirmation',
              row.reservationId
            )
            .then(() => {
              store.dispatch('app/showAlert', {
                type: 'success',
                message: 'Pending Confirmation Status Changed',
              })
              EventBus.$emit('global-table-view-refresh')
            })
        },
      },
      {
        icon: () => 'open_in_browser',
        viewBox: '0 0 24 24',
        title: () => 'Client View',
        condition: () => canOpenCharterUpQuote && row.isCharterUpQuote,
        path: () => `/charterup/reservations/${row.reservationId}`,
      },
    ]
  }

  function editPurchaseOrder(row: Row) {
    const component = () =>
      import('@/components/PurchaseOrderSidebar.vue')
    dispatch('app/openSidebarDialog', {
      data: {
        purchaseOrderNumber: row.poNumber,
        purchaseOrderStatus: row.poStatus,
        purchaseOrderStatuses: row.purchaseOrderStatuses,
        reservationExternalId: row.externalId,
        title: "PO Status",
      },
      component,
    })
  }

  return Vue.component('WrappedActions', {
    props: {
      row: {
        type: Object,
        default: () => ({}),
      },
      handleAction: {
        type: Function,
        default: () => null,
      },
      rowProps: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      ...authComputed,
      isAdmin() {
        const roles = this.currentUserProfile?.roles || []
        return !!roles.find(
          (r: any) =>
            r.roleName === 'is_free_admin' ||
            'is_paid_admin' ||
            'is_broker_admin' ||
            'is_admin_admin'
        )
      },
      isAccountsReceivable() {
        const roles = this.currentUserProfile?.roles || []
        return !!roles.find(
          (r: any) =>
            r.roleName === 'is_accounts_receivable'
        )
      },
      canOpenCharterUpQuote() {
        const roles = this.currentUserProfile?.roles || []
        const charterUpRole = roles.find(
          (r: any) => r.roleName === 'is_charterup_sales'
        )
        const charterUpPermissions = charterUpRole?.permissions || []
        return !!charterUpPermissions.find(
          (p: any) => p.permissionName === 'canSendCharterUPQuote'
        )
      },
    },
    render(createElement: any) {
      return createElement(component, {
        props: {
          row: this.row,
          handleAction: this.handleAction,
          rowProps: this.rowProps,
          menu: MenuDataMapper(
            this.row,
            this.rowProps,
            this.handleAction,
            this.canOpenCharterUpQuote,
            this.isAdmin,
            this.isAccountsReceivable
          ),
        },
      })
    },
  })
}
